import * as Yup from "yup";

function viajeRetiroValido(value) {
  if(value.esRetiroPersonalizado != true)
  {
    if(value.estacionRetiro != "" && value.estacionRetiro != undefined)
    {
      return true;
    }
    else
    {
      return false;
    }
  }
  else
  {
    return true;
  }
}

function viajeRetiroPersonalizadoValido(value)
{
  if(value.esRetiroPersonalizado == true)
  {
    if(value.direccionRetiro != "" && value.direccionRetiro != undefined)
    {
      return true;
    }
    else
    {
      return false;
    }
  }
  else
  {
    return true;
  }
}

function viajeRetiroPersonalizadoComunaValido(value)
{
  if(value.esRetiroPersonalizado == true)
  {
    if(value.comunaRetiro != "" && value.comunaRetiro != undefined)
    {
      return true;
    }
    else
    {
      return false;
    }
  }
  else
  {
    return true;
  }
}

function yupViajeRetiro(msg) {
  return function () {
    return Yup.string().test({
      name: "yupViajeRetiro",
      exclusive: false,
      message: `no es un rut valido`,
      test: (value) => viajeRetiroValido(value),      
    });
  };
}

function yupViajeRetiroPersonalizado(msg) {
  return function () {
    return Yup.string().test({
      name: "yupViajeRetiroPersonalizado",
      exclusive: false,
      message: `no es un rut valido`,
      test: (value) => viajeRetiroPersonalizadoValido(value),      
    });
  };
}

function yupViajeRetiroPersonalizadoComuna(msg) {
  return function () {
    return Yup.string().test({
      name: "yupViajeRetiroPersonalizadoComuna",
      exclusive: false,
      message: `no es un rut valido`,
      test: (value) => viajeRetiroPersonalizadoComunaValido(value),      
    });
  };
}

export {  viajeRetiroValido, yupViajeRetiro,viajeRetiroPersonalizadoValido,yupViajeRetiroPersonalizado,viajeRetiroPersonalizadoComunaValido,yupViajeRetiroPersonalizadoComuna };
