
import { ref, defineComponent, onMounted, computed, watch } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import * as Yup from "yup";
import { useRouter } from "vue-router";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import moment from "moment";
import { viajeRetiroValido ,viajeRetiroPersonalizadoValido,viajeRetiroPersonalizadoComunaValido} from "@/core/validators/YupRetiro";
import { viajeDevolucionValido ,viajeDevolucionPersonalizadoValido,viajeDevolucionPersonalizadoComunaValido} from "@/core/validators/YupDevolucion";

interface ReservationDetails {
  //reservaId: string;
  estacionRetiro: string;
  estacionDevolucion: string;
  esRetiroPersonalizado:boolean|undefined,
  esDevolucionPersonalizada:boolean|undefined,
  direccionRetiro: string;
  direccionDevolucion: string;
  comunaRetiro: string;
  comunaDevolucion: string;
  fechaRetiro: Date | undefined;
  fechaDevolucion: Date | undefined;
  horaRetiro: Date | undefined;
  horaDevolucion: Date | undefined;
  clientId: number;
  driverId: number;
  rut: string;
  nombre: string;
  telefono: string;
  email: string;
}

export default defineComponent({
  name: "dashboard",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const minDate = ref(new Date());
    const maxDate = ref(new Date()); 
    const submitButton1 = ref<HTMLButtonElement | null>(null);
    const reservationValidator = Yup.object().shape({
      estacionRetiro: Yup.string()
        .test("yupViajeRetiro",  "Estación Retiro ingresada no es válido", function (value) {
          return viajeRetiroValido(reservationDetails.value);
        })
        .label("estacionRetiro"),
      direccionRetiro: Yup.string()       
        .test("yupViajeRetiroPersonalizado",  "Estación Retiro ingresada no es válido", function (value) {
          return viajeRetiroPersonalizadoValido(reservationDetails.value);
        })
        .label("direccionRetiro"),
      /*comunaRetiro: Yup.string()       
        .test("yupViajeRetiroPersonalizadoComuna",  "Comuna Retiro ingresada no es válido", function (value) {
          return viajeRetiroPersonalizadoComunaValido(reservationDetails.value);
        })
        .label("comunaRetiro"),*/
      estacionDevolucion: Yup.string()
        .test("yupViajeDevolucion",  "Estación Devolucion ingresada no es válido", function (value) {
          return viajeDevolucionValido(reservationDetails.value);
        })
        .label("estacionDevolucion"),
      direccionDevolucion: Yup.string()       
        .test("yupViajeDevolucionPersonalizado",  "Estación Devolucion ingresada no es válido", function (value) {
          return viajeDevolucionPersonalizadoValido(reservationDetails.value);
        })
        .label("direccionDevolucion"),
      comunaDevolucion: Yup.string()       
        .test("yupViajeDevolucionPersonalizadoComuna",  "Comuna Devolucion ingresada no es válido", function (value) {
          return viajeDevolucionPersonalizadoComunaValido(reservationDetails.value);
        })
        .label("comunaDevolucion"),        

    });
    const currentUser = computed(() => {
      return store.getters.currentUser;
    });
    onMounted(() => {
      store.commit(Mutations.SET_RESERVA_ID, null);
      setCurrentPageTitle("Dashboard");
      obtenerSucursales();
      obtenerComunas();
      maxDate.value = moment().add(9, 'month').toDate();
    });
    const reservationDetails = ref<ReservationDetails>({
      estacionRetiro: "",
      estacionDevolucion: "",
      direccionRetiro: "",
      direccionDevolucion: "",
      comunaRetiro: "",
      comunaDevolucion: "",
      esRetiroPersonalizado:false,
      esDevolucionPersonalizada:false,
      fechaRetiro: moment().add(1, 'days').toDate(),
      fechaDevolucion: moment().add(2, 'days').toDate(),
      horaRetiro: moment("20111031 12:00:00", "YYYYMMDD hh:mm:ss").toDate(),
      horaDevolucion: moment("20111031 12:00:00", "YYYYMMDD hh:mm:ss").toDate(),
      nombre: `${currentUser.value.given_name} ${currentUser.value.family_name}`,
      email: currentUser.value.email,
      telefono: currentUser.value.phone_number,
      rut: currentUser.value?.driverId?.toString(),
      clientId: currentUser.value?.clientId,
      driverId: currentUser.value?.driverId,
    });
    watch(() => reservationDetails.value.estacionRetiro, (newValue) => {
      if(reservationDetails.value.estacionDevolucion=='')
        reservationDetails.value.estacionDevolucion = newValue;
    });
    const saveChanges = () => {
      console.log("x");
      if (submitButton1.value) {
        // Activate indicator
        submitButton1.value.setAttribute("data-kt-indicator", "on");
        submitButton1.value!.disabled = true;
        store
          .dispatch(Actions.INS_RESERVA, reservationDetails.value)
          .then(() => {
            submitButton1.value?.removeAttribute("data-kt-indicator");
            console.log(
              "INS RESERVA " + JSON.stringify(store.getters.currentReserva)
            );
            store.commit(
              Mutations.SET_RESERVA_ID,
              store.getters.currentReserva.reservaId
            );
            
            router.push({ name: "available-fleet" });
          })
          .catch(() => {
            //Deactivate indicator
            submitButton1.value?.removeAttribute("data-kt-indicator");
            // eslint-disable-next-line
            submitButton1.value!.disabled = false;

            const [error] = Object.keys(store.getters.getReservaErrors);
            Swal.fire({
              text: store.getters.getReservaErrors[error],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Intentar nuevamente!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });
      }
    };
    
    const sucursales = computed(() => {
      return store.getters.allSucursales.filter(c=>c.esLocacionEuropcar);
    });
    const comunas = computed(() => {
      return store.getters.allComunas;
    });
    const obtenerSucursales = () => {
      store
        .dispatch(Actions.GET_SUCURSALES,currentUser.value.clientId) 
        .then(() => {
          loading.value = false;
        })
        .catch(() => {
          const [error] = Object.keys(store.getters.getCuentaErrors);
        });
    };

    const obtenerComunas = () => {
      store
        .dispatch(Actions.GET_COMUNAS)
        .then(() => {
          loading.value = false;
        })
        .catch(() => {
          const [error] = Object.keys(store.getters.getComunaErrors);
        });
    };

    const dateSelectDesde = (valor) => {
      console.log('desde ' + valor);
      reservationDetails.value.fechaDevolucion = moment(valor).add(1, 'days').toDate();
    };

    const loading = ref(true);
    return {
      loading,
      currentUser,
      sucursales,
      submitButton1,
      saveChanges,
      reservationDetails,
      reservationValidator, moment, dateSelectDesde,comunas,
      minDate,maxDate
    };
  },
});
