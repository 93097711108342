import * as Yup from "yup";

function viajeDevolucionValido(value) {
  if(value.esDevolucionPersonalizada != true)
  {
    if(value.estacionDevolucion != "" && value.estacionDevolucion != undefined)
    {
      return true;
    }
    else
    {
      return false;
    }
  }
  else
  {
    return true;
  }
}

function viajeDevolucionPersonalizadoValido(value)
{
  if(value.esDevolucionPersonalizada == true)
  {
    if(value.direccionDevolucion != "" && value.direccionDevolucion != undefined)
    {
      return true;
    }
    else
    {
      return false;
    }
  }
  else
  {
    return true;
  }
}

function viajeDevolucionPersonalizadoComunaValido(value)
{
  if(value.esDevolucionPersonalizada == true)
  {
    if(value.comunaDevolucion != "" && value.comunaDevolucion != undefined)
    {
      return true;
    }
    else
    {
      return false;
    }
  }
  else
  {
    return true;
  }
}

function yupViajeDevolucion(msg) {
  return function () {
    return Yup.string().test({
      name: "yupViajeDevolucion",
      exclusive: false,
      message: `no es un rut valido`,
      test: (value) => viajeDevolucionValido(value),      
    });
  };
}

function yupViajeDevolucionPersonalizado(msg) {
  return function () {
    return Yup.string().test({
      name: "yupViajeDevolucionPersonalizado",
      exclusive: false,
      message: `no es un rut valido`,
      test: (value) => viajeDevolucionPersonalizadoValido(value),      
    });
  };
}

function yupViajeDevolucionPersonalizadoComuna(msg) {
  return function () {
    return Yup.string().test({
      name: "yupViajeDevolucionPersonalizadoComuna",
      exclusive: false,
      message: `no es un rut valido`,
      test: (value) => viajeDevolucionPersonalizadoComunaValido(value),      
    });
  };
}

export {  viajeDevolucionValido, yupViajeDevolucion,viajeDevolucionPersonalizadoValido,yupViajeDevolucionPersonalizado,viajeDevolucionPersonalizadoComunaValido,yupViajeDevolucionPersonalizadoComuna };
